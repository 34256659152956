























































export default {
  name: 'RedeemDialog',
  data() {
    return {
      loading: false,
      dialog: false,
      upgradeForce: false,
      info: {
        plan: '',
        duration: '',
        new_expiration_date: '',
        keyItem: ''
      }
    };
  },
  methods: {
    processDuration(duration) {
      if(this.info.plan === 'unlock') return 'Lifetime';
      return duration + ' days';
    },
    processExpiration(expiration) {
      if(this.info.plan === 'unlock') return 'Never';
      return expiration;
    },
    upgrade(key: string) {
      this.loading = true;
      const upgradeForce = this.upgradeForce;
      this.$emit('redeem', {key, upgradeForce });
    },
    loadingChange(status: boolean) {
      this.loading = status;
    },
    open(info: any, upgradeForce = false) {
      this.dialog = true;
      this.info = info;
      this.upgradeForce = upgradeForce;
    },
    close() {
      this.loading = false;
      this.dialog = false;
      this.info = {
        plan: '',
        duration: '',
        new_expiration_date: '',
        keyItem: ''
      };
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },
  computed: {
    new_expiration_date() {
      return this.$moment(this.info.new_expiration_date).format('YYYY-MM-DD HH:mm:ss');
    },
    plan() {
      return this.capitalizeFirstLetter(this.info.plan);
    }
  }
};
